import FirstClassStaffing from '@/companies/1stClassStaffing';
import BrandPack from '@/companies/BrandPack';
import EZPayroll from '@/companies/EZPayroll';
import Premier from '@/companies/Premier';
import AllBlogs from '@/companies/Premier/pages/AllBlogs';
import Jobs from '@/companies/Premier/pages/Jobs/Jobs';
import OurTechnology from '@/companies/Premier/pages/OurTechnology';
import Philosophy from '@/companies/Premier/pages/Philosophy';
import BlogPage from '@/companies/Premier/pages/BlogPage';
import Contact from '@/companies/Premier/pages/Contact';


export const pages = [
  {
    routeName: '/',
    Component: Premier,
  },
  {
    routeName:'/1stclassstaffing',
    Component:FirstClassStaffing
  },
  {
    routeName:'/brandpack',
    Component:BrandPack
  },
  {
    routeName:'/ezpayroll',
    Component:EZPayroll
  },
  {
    routeName:'/premier',
    Component:Premier
  },
  {
    routeName:'/blog/:blogId',
    Component:BlogPage
  },
  {
    routeName:'/blog',
    Component:AllBlogs
  },
  {
    routeName:'/jobs',
    Component:Jobs
  },
  {
    routeName:'/our-technology',
    Component:OurTechnology
  },
  {
    routeName:'/philosophy',
    Component:Philosophy
  },
  {
    routeName:'/contact',
    Component:Contact
  },
]