export const externalLinks = {
    sjFacebook: 'https://www.facebook.com/swipejobs',
    sjInstagram: 'https://www.instagram.com/swipejobs',
    sjLinkedin: 'https://www.linkedin.com/company/swipejobs',
    psLinkedin:'https://www.linkedin.com/company/swipejobs-for-premier',
    swipejobsSupport: 'support@swipejobs.com',
    swipejobsPrivacy: 'privacy@swipejobs.com',
    swipejobsInfo: 'info@swipejobs.com',
    workerAppIos: 'https://apps.apple.com/us/app/swipejobs/id1500533063',
    workerAppAndroid: 'https://play.google.com/store/apps/details?id=exchange.ondemand.swipejobs.worker',
    customerAppIos: 'https://apps.apple.com/app/apple-store/id1495004685',
    customerAppAndroid: 'https://play.google.com/store/apps/details?id=exchange.ondemand.swipejobs.customer',
    swipejobsWebsite: 'https://www.swipejobs.com',
    customerDesktop: 'https://sj-customer.swipejobs.com/login',
    careersLink: 'https://swipejobs.breezy.hr/',
    contactFormUrl: 'https://formspree.io/mnqgavjz',
    sjDevUrlPrefix:'https://api.sj.preprod.sj-exchange.com/v3/',
    sjPreProdUrlPrefix: 'https://api.sj.preprod.sj-exchange.com/v3/',
    sjProdUrlPrefix: 'https://api.sj.prod.sj-exchange.com/v3/',
    sjSendSms: 'desktop-notifications/external/notification/sendToAddress',
    sjGuestToken: 'identity/sessions/guest',
    sjBranchesData: 'branch-view/public/branches',
    sjWorkerFaq: 'https://www.swipejobs.com/workers#faq',
    sjBusinessFaq: 'https://www.swipejobs.com/business#faq',
    sjContactUs: 'https://www.swipejobs.com/about#contact-us',
    sjPrivacy: 'https://www.swipejobs.com/privacy#top',
    premierRegisterLogin: 'https://hrcenter.tempworks.io/en/premieremployees',
    premierHRCenter: 'https://webcenter.ontempworks.com/PremierEmployees/PremierEmployeeSolutions/Account/LogIn',
    workerLandingDownload: 'https://landing.swipejobs.com/app-download',
    premierBenefits: 'https://benefits.thepremierpride.com',
    gsaSchedule:'https://www.corestaff.com/-/media/corestaff/gsa-schedule.pdf',
    jobsHomePage:'https://www.swipejobs.com/jobs',
    transparencyInCoverage: "https://sisconosurprise.com/organizations/202824401.html",
    applicantPreProdUrlPrefix: 'https://applicant-capture-pages.sj.preprod.sj-exchange.com/',
    applicantProdUrlPrefix: 'https://swipejobs.com/',
    googleMapsUrlPrefix: 'https://www.google.com/maps/dir/?api=1&destination='
};
