import React, {
    useEffect,
    useState
} from 'react';
import { useParams } from 'react-router-dom';
import blogsData from '../../blogsData.json';
import RecursiveElement from './RecursiveElement';
import '../../styles.scss';

export interface BlogNode {
    type: string
    content: string
    nodes?: BlogNode[],
    props?: React.HTMLProps<HTMLElement>
}


type RouteParams = {
    blogId: string
}

const BlogPage = () => {
    const { blogId } = useParams<RouteParams>();

    const [currentBlog, setCurrentBlog] = useState<any | undefined>(undefined);

    useEffect(() => {
        const blog: any = blogsData.blogs.find((blog) => blog.id === blogId);
        console.log({ blog });

        setCurrentBlog(blog);
    }, [blogId]);

    return (
        <div id='top' className='component-wrapper'>
            <div className='component-wrapper__inner'>
                {currentBlog && (
                    <div className='mt-4'>
                        <h1 className='my-4'>{currentBlog.title}</h1>
                        <img className='mx-auto d-block w-100' src={currentBlog.banner} alt={currentBlog.title}/>
                        {currentBlog?.nodes?.map((blogNode: BlogNode, index: number) => (
                            <RecursiveElement key={index} nodes={blogNode}/>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default BlogPage;
