import { TrackingNames, TrackingNameType} from '@/models/trackingModel';

export interface INavLink {
  title: string;
  link: string;
  trackingName: TrackingNameType
} 

const NAV_LINKS: INavLink[] = [
  {
    title: 'philosophy',
    link: '/philosophy#top',
    trackingName: TrackingNames.NavPhilosophyClick
  },
  {
    title: 'blog',
    link: '/blog#top',
    trackingName: TrackingNames.NavBlogClick
  },
  {
    title: 'contact',
    link: '/contact#top',
    trackingName: TrackingNames.NavContactClick
  },
  {
    title: 'ourTechnology',
    link: '/our-technology#top',
    trackingName: TrackingNames.NavTechnologyClick
  },
  {
    title: 'jobsLabel',
    link: '/jobs#top',
    trackingName: TrackingNames.NavJobsClick
  }
]

export default NAV_LINKS