import * as React from 'react';

import './styles.scss';
import {
    ImageContentCol,
    IMAGE_CONTENT_COL_SPACING
} from '@/components/ImageContentCol';
import { useTranslationHook } from '@/hooks/useTranslationHook';
import { getImageUrl } from '@/utils';
import RenderParagraphs from '@/components/RenderParagraphs';

const OurTechnology = () => {
    const { translate } = useTranslationHook();

    return (
        <div id='top' className='our-technology-page'>
            {/*<h1>
             Our Technology
             </h1>
             <ImageContentCol
             leftContent={<ContentCol
             listContent={[
             'Talk about the advantage of the technology (world leading?)',
             'How is it changing staffing',
             'Get people 24x7'
             ]}
             trackingCategory={TrackingCategories.OurTechnology}
             />}
             rightContent={<ContentCol
             listContent={[
             'Order any time',
             'Workers can onboard and be ready in minutes',
             'Interactive training and testing'
             ]}
             trackingCategory={TrackingCategories.OurTechnology}
             />}
             />*/}

            <div className='animated-container'>
                <ImageContentCol
                    title={translate('ourTechnology')}
                    spacing={IMAGE_CONTENT_COL_SPACING.NONE}
                    leftContent={<RenderParagraphs translateLabel='ourTechnologyLeftParagraphs'/>

                    }
                    rightContent={
                        <div className='image-container'>
                            <img
                                src={getImageUrl('partner-technology.png')}
                                className={'home-image'}
                                alt={'partner-technology'}
                            />
                        </div>
                    }
                />


            </div>
            <div className='home-paragraph'>
                <RenderParagraphs translateLabel='ourTechnologyBottomParagraphs'/>
            </div>
        </div>
    );
};
export default OurTechnology;
