import * as React from 'react';
import './styles.scss';
import reactStringReplace from 'react-string-replace';
import { ContentCol } from '@/components/ContentCol';
import { ImageCol } from '@/components/ImageCol';
import {
    IMAGE_CONTENT_COL_SPACING,
    ImageContentCol
} from '@/components/ImageContentCol';
import { PartnerBanner } from '@/components/PartnerBanner';
import * as HOME from '@/data/pages/partner-premier';
import { useTranslationHook } from '@/hooks/useTranslationHook';
import { TrackingCategories } from '@/models/trackingModel';
import { full_partner_name } from '@/models/name';
import RenderParagraphs from '@/components/RenderParagraphs';

const Premier = () => {
    const {
        translate,
        translateObject
    } = useTranslationHook();
    const listContentLeft: any = translateObject('homeDotPointListLeft');
    const listContentRight: any = translateObject('homeDotPointListRight');
    return (
        <>
            <div id='top' className='home-page mb-4'>
                <PartnerBanner    {...HOME.PARTNER_BANNER}
                                  img={{
                                      src: '/images/premier.png',
                                      alt: 'premier'
                                  }}/>
                <ImageContentCol leftContent={
                    <ContentCol title={[{ text: translate('homeLeftTitle') }]}
                                listContent={listContentLeft}
                                trackingCategory={TrackingCategories.Premier}/>
                }
                                 rightContent={
                                     <ContentCol title={[{ text: translate('homeRightTitle') }]}
                                                 listContent={listContentRight}
                                                 trackingCategory={TrackingCategories.Premier}/>
                                 }
                />
                <p className='home-paragraph'>
                    {reactStringReplace(
                        translate('homeParagraphVariant'),
                        '{0}',
                        (match, i) => (
                            <span key={full_partner_name}>
                  {full_partner_name}
              </span>
                        ))}
                </p>
                <ImageContentCol spacing={IMAGE_CONTENT_COL_SPACING.NONE}
                                 leftContent={
                                     <>
                                         <h3>{translate('hiringTitle')}</h3>
                                         <p>{translate('homeParagraphVariantDescription')}</p>
                                         <RenderParagraphs translateLabel='hiringParagraphs'/>
                                         <i>{translate('hiringParagraph4ItalicPart')}</i>
                                     </>
                                 }
                                 rightContent={
                                     <ImageCol src={'partner-hiring.png'} alt={'laptop-animate'}/>
                                 }/>
                {/*<PartnerFooter/>*/}
            </div>
        </>
    );
};
export default Premier;
