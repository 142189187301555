import * as React from 'react';
import {
    Link,
    useLocation
} from 'react-router-dom';
import { useTranslationHook } from '@/hooks/useTranslationHook';
import blogData from '../../blogsData.json';
import './styles.scss';

const PARTNER_NAME = 'swipejobs for Premier';

const AllBlogs = () => {
    const { translate } = useTranslationHook();

    return (
        <div id='top' className='home-page container'>
            <div className={'component-wrapper'}>
                <h1 className='main-title'>
                    {PARTNER_NAME} {translate('blog')}
                </h1>
            </div>
            <div>{translate('blogPageSubHeading')}</div>
            <div>
                {blogData.blogs.map((blog) => (
                    <Link
                        to={{
                            pathname: `/blog/${blog.id}`
                        }}
                        key={blog.id}
                        className='card my-4 overflow-hidden text-decoration-none text-body'
                    >
                        <div className='card-container'>
                            <img
                                className='card-img'
                                src={blog.banner}
                                alt={blog.title + 'banner'}
                            />
                            <div className='content-container'>
                                <h2>{blog.title}</h2>
                                <p>
                                    {blog?.nodes[0]?.content?.substring(0, 200)}...{' '}
                                    <i>{`>> `}Read More</i>
                                </p>
                            </div>
                        </div>
                    </Link>
                ))}
            </div>
        </div>
    );
};
export default AllBlogs;
